import { useSelector } from 'react-redux';

import { Loading, PlanCard } from '@jaramba-frontend/core/components';
import { BillingIntervals } from '@jaramba-frontend/core/types';
import { getPriceWithCurrency, getPlanData, getProductPrice } from '@jaramba-frontend/core/utils';

import { RootState } from '../../store/store';
import styles from './styles.module.scss';

interface Props {
    onSelectPlanClick: (billingInterval: BillingIntervals) => void;
}

const Products = ({ onSelectPlanClick }: Props) => {
    const { product } = useSelector((state: RootState) => state.product);

    if (!product) {
        return <Loading />;
    }

    const trialEndDate = product?.campaign?.debitDate ?? undefined;
    const { offerTextAnnual, offerTextMonthly, monthlyPrice, annualPrice } = getPlanData({
        trialEndDate,
        plans: product?.campaign?.plans,
        prices: product.prices,
    });

    const annualPricePerMonth = getProductPrice(BillingIntervals.Annualy, product.prices, true);

    return (
        <div className={styles.root}>
            <p className={styles.title}>Välj din prenumerationsplan</p>

            <div className={styles.productsContainer}>
                <PlanCard
                    price={annualPricePerMonth}
                    billingInterval={BillingIntervals.Annualy}
                    offerText={offerTextAnnual}
                    originalAnnualPrice={getPriceWithCurrency(annualPrice)}
                    showYearlyAsMonthly={true}
                    onSelectPlanClick={onSelectPlanClick}
                />
                <PlanCard
                    price={monthlyPrice}
                    billingInterval={BillingIntervals.Monthly}
                    offerText={offerTextMonthly}
                    onSelectPlanClick={onSelectPlanClick}
                />
            </div>
        </div>
    );
};

export default Products;
