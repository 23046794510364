import { Button } from '@jaramba-frontend/core/components';
import { BillingIntervals } from '@jaramba-frontend/core/types';

import { BILLING_TEXT } from '../../constants';
import { getPriceWithCurrency } from '../../utils';
import styles from './styles.module.scss';

interface Props {
    price: number;
    billingInterval: BillingIntervals;
    offerText?: string;
    originalAnnualPrice?: string;
    showYearlyAsMonthly?: boolean;
    showSavingPercentLabel?: boolean;
    onSelectPlanClick: (billingInterval: BillingIntervals) => void;
}

const PlanCard = ({
    price,
    billingInterval,
    offerText,
    originalAnnualPrice,
    showYearlyAsMonthly,
    showSavingPercentLabel,
    onSelectPlanClick,
}: Props) => {
    const isAnnualBilling = billingInterval === BillingIntervals.Annualy;

    return (
        <div className={styles.card}>
            {isAnnualBilling && (
                <div className={styles.cardHeader}>
                    {showSavingPercentLabel && (
                        <div className={styles.savingPercentLabel}>
                            <span>Spara upp till 30%</span>
                        </div>
                    )}

                    <h4 className={styles.text}>🔥 BÄST VÄRDE</h4>
                </div>
            )}

            <div className={styles.cardBody}>
                <div className={styles.planDetails}>
                    <div>
                        <h3 className={styles.billing}>{BILLING_TEXT[billingInterval].full}</h3>
                        {offerText && <p className={styles.offerText}>{offerText}</p>}
                    </div>
                    <div className={styles.priceContainer}>
                        <p className={styles.amount}>{price}</p>
                        <div>
                            <span className={styles.currency}>SEK</span>
                            <span className={styles.billing}>
                                /{BILLING_TEXT[showYearlyAsMonthly ? BillingIntervals.Monthly : billingInterval].short}
                            </span>
                        </div>
                    </div>
                </div>

                <p className={styles.billingDetails}>
                    Faktureras {BILLING_TEXT[billingInterval].full} {originalAnnualPrice ?? getPriceWithCurrency(price)}
                </p>

                <Button className={styles.button} fullWidth onClick={() => onSelectPlanClick(billingInterval)}>
                    Starta din gratis provperiod
                </Button>
            </div>
        </div>
    );
};

export default PlanCard;
